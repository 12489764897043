import React from "react"
import { Helmet } from "react-helmet"
import "typeface-noto-serif-jp"
import "./style.css"
import JSONData from "../content/excerpts.json"

export default function Books() {
  return (
    <div className="journal">
      <Helmet>
        <meta charSet="utf-8" />
        <title>jmdavril</title>
      </Helmet>
      {JSONData.excerpts.map((data, index) => {

        function contentMarkup(content) {
          return {__html: `${content}`};
        }

        return (
          <div className="excerpt" key={`excerpt-${index}`}>
            {/*<p className="excerpt-date">{data.published_at}</p>*/}
            <p className="excerpt-content" dangerouslySetInnerHTML={contentMarkup(data.content)}></p>
              <p className="excerpt-title">{data.title}</p>
              <p className="excerpt-authors">{data.authors}</p>
          </div>
        )
      })}
    </div>
  )
}



